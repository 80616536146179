import "lazysizes";
import {FloatLabels} from "@keodesign/float-labels";


var html_classes = document.documentElement.classList;

html_classes.remove("no-js");
html_classes.add("js");

window.onload = function() {    
	setupSlideMenu();
	setupLoginForm();
	new FloatLabels(".form-group");
	new FloatLabels(".js-float-wrap");
	const map_click = document.getElementById("map-click");
	if (map_click) {
		map_click.addEventListener("click",() => {
			// shield the map on mobile for easy scrolling
			map_click.style.display = "none";
		});
	}
};

export function setupLoginForm(){
	
	const login_form = document.querySelector(".login-form");
	if (login_form){
		const animate_flag = login_form.classList.contains("animate");
		
		const animate_login = () => {
	
			const hidden_element = login_form.querySelectorAll(".hidden-fields");
			hidden_element.forEach( (el) =>{
				el.classList.add("active");
			});
			document.querySelector(".summary").classList.add("hide");
			document.querySelector(".login-btn").style.pointerEvents = "all";
		

			
			login_form.removeEventListener("mouseover", animate_login);  
		};
		if (animate_flag){
			animate_login();
		}
		else{
			login_form.addEventListener("mouseover", animate_login);
		}
	
		
	}
}
export function setupSlideMenu(){
	const menu_buttons = [...document.querySelectorAll("#mobile-menu, .slide-menu h3")];
	menu_buttons.forEach(el => {
		el.addEventListener("click", ()=>{
			$("body").toggleClass("slide-menu-active");
		});
	});
	const menu_items = [...document.querySelectorAll(".slide-menu li")];
	menu_items.forEach((element) => {		
		if ([...element.querySelectorAll("ul")].length > 0) {
			element.insertAdjacentHTML("beforeend","<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			
			// expander.click(function(e) {
			// 	e.stopPropagation();
			// 	$el.toggleClass("expand-active");
			// });
		}
	});
	const menu_expanders = [...document.querySelectorAll(".slide-menu .expand")];
	menu_expanders.forEach(el => {
		el.addEventListener("click", (e)=> {
			e.stopPropagation();
			el.closest("li").classList.toggle("expand-active");
		});
	});

}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});
